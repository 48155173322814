<template>
  <div class="tender-list-detail-content">
    <template v-if="!isTenderLoading">
      <TenderDetailHeader
        :supplier-id="supplierId"
        :tender="updatedTender"
        :show-give-offer-button="isGiveOfferButtonVisible"
        :show-reject-price-request-button="isRejectPriceButtonVisible"
        :show-mark-as-done-button="isMarkAsDoneButtonVisible"
        :show-create-price-request-button="isCreatePriceRequestButtonVisible"
        @on-create-price-request-click="handleCreatePriceRequestClick"
        @on-price-request-rejected-click="handleRejectPriceRequestClick"
        @on-mark-as-done-click="handleMarkAsDoneClick"
        @on-new-offer-click="handleNewOfferButtonClick"
      >
        <template #tabs>
          <TenderDetailTabs
            :tabs="currentTabs"
            :active-tab-label="activeTabLabel"
            @tab-change="handleClickedTab"
          />
        </template>
        <template #agreementResponsible>
          <AgreementResponsible
            :supplier-id="supplierId"
            :tender="updatedTender"
          />
        </template>
      </TenderDetailHeader>

      <div class="tender-detail-content">
        <component
          :is="tabComponents.get(activeTabLabel) || PriceRequestDetails"
          v-if="updatedTender"
          v-bind="getComponentProps(activeTabLabel)"
        />
      </div>

      <reject-price-request-modal
        v-model="showRejectPriceRequestModal"
        :tender-id="updatedTender.tenderId"
        :supplier-request-id="supplierRequestId"
        :user-id="userId"
        :supplier-id="supplierId"
        @price-request-rejected="onPriceRejected"
      />

      <reject-price-request-success-modal
        v-model="showRejectPriceRequestSuccessModal"
        @primary-click="handleOnPriceRejectedSuccess"
      />

      <complete-tender-offer-modal
        v-model="showCompleteTenderOfferModal"
        :tender-id="updatedTender.tenderId"
        :service-offer-id="selectedServiceOfferId"
        @completed="onOfferCompleted"
      />

      <create-price-request-modal
        v-model="showCreatePriceRequestModal"
        :supplier-service-types="supplierServiceTypes"
        :supplier-users="supplierUsers"
        :tender="updatedTender"
        :supplier-id="supplierId"
      />
    </template>
    <div v-else class="tender-list-detail-content__spinner-wrapper">
      <b-spinner class="tender-list-detail-content__loader" type="grow" small />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, watch, DefineComponent } from "vue";
import { useRouter, useRoute } from "vue-router";

import MessagesWrapper from "@/components/MessagesWrapper/MessagesWrapper.vue";
import ServiceReportWrapper from "@/components/ServiceReport/ServiceReportWrapper.vue";
import PriceRequestDetails from "@/components/PriceRequestDetails/PriceRequestDetails.vue";
import SupplierOffersWrapper from "@/components/SupplierOffersWrapper/SupplierOffersWrapper.vue";
import AgreementWrapper from "@/components/AgreementWrapper/AgreementWrapper.vue";

import TenderDetailHeader from "./TenderDetailHeader.vue";
import TenderDetailTabs from "./TenderDetailTabs.vue";
import AgreementResponsible from "@/components/AgreementResponsible/AgreementResponsible.vue";

import RejectPriceRequestModal from "@/components/RejectPriceRequestModal/RejectPriceRequestModal.vue";
import RejectPriceRequestSuccessModal from "@/components/RejectPriceRequestSuccessModal/RejectPriceRequestSuccessModal.vue";
import CreatePriceRequestModal from "@/components/CreatePriceRequestModal/CreatePriceRequestModal.vue";
import CompleteTenderOfferModal from "../CompleteTenderOfferModal/CompleteTenderOfferModal.vue";

import {
  RequestState,
  TenderState,
  TenderViewTabLabels,
  JobType,
  OfferStatus,
} from "@/custom-types/GeneralTypes";

import {
  isTenderSingleJob,
  getServiceOfferFromTender,
  getCustomerWantedStartDate,
  getPriceRequestSentDate,
  getSurveyDateTime,
  getPriceRequestMessage,
} from "@/utilities/offerUtils";

import { formatToLongDateString } from "@/utilities/dateUtils";
import { RouteNames } from "@/router/types";
import { useSupplierStore } from "@/stores/supplier";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { useTenderApi } from "@/services/api/useTenderApi";
import { useSupplierApi } from "@/services/api/useSupplierApi";
import { useCmsStore } from "@/stores/cms";
import { useUserStore } from "@/stores/user";
import type {
  Tender,
  ServiceTypePackagePortfolio,
  // PriceRequest,
} from "@/stores/tender/types";
import type { SupplierUser } from "@/stores/supplierUsers/types";
import { ServiceType } from "@/stores/supplier/types";
import { ServiceCategory } from "@/stores/supplier/types";

const VALID_STATES = [
  TenderState.OFFERED,
  TenderState.ACCEPTED,
  TenderState.COMPLETED,
];

export interface ServiceCategoryBase {
  id?: number;
  categoryLabel: string;
}

export interface ServiceCategoryWithOnlyIdAndCategoryLabel
  extends ServiceCategoryBase {
  serviceCategoryId?: number;
}

interface FormattedServiceType {
  id: number;
  title: string;
  label: string;
  categories: FormattedCategory[];
}

interface FormattedCategory {
  title: string;
  label: string;
  id?: number;
}

interface CmsServiceType {
  label: string;
  name?: string;
  serviceCategories?: { label: string; title?: string }[];
}

const props = defineProps<{
  tender: Tender;
  supplierId: number;
  supplierUsers: SupplierUser[] | null;
}>();

const router = useRouter();
const route = useRoute();
const supplierStore = useSupplierStore();
const cmsStore = useCmsStore();
const userStore = useUserStore();

const { getSupplierServiceCategories } = useSupplierApi();
const { getTender, getActivityFormFrequencyOptions } = useTenderApi();

const showRejectPriceRequestModal = ref(false);
const showRejectPriceRequestSuccessModal = ref(false);
const showCreatePriceRequestModal = ref(false);
const showCompleteTenderOfferModal = ref(false);
const frequencyOptions = ref<string[] | null>(null);
const supplierServiceTypes = ref<FormattedServiceType[]>([]);
const updatedTender = ref<Tender>({} as Tender);
const isTenderLoading = ref(true);

// data for PriceRequestDetails props
const referenceCode = computed(
  () => `#${updatedTender.value?.externalTenderId}`,
);

const serviceCategory = computed(() => {
  const { matchedServiceType, matchedServiceCategory } =
    getServiceTypeAndServiceCategory();
  const jobType = isTenderSingleJob(updatedTender.value)
    ? JobType.SINGLE
    : JobType.REPEATING;

  return matchedServiceCategory?.title
    ? `${matchedServiceType?.name} - ${matchedServiceCategory.title} (${jobType})`
    : "";
});

const desiredStartDate = computed(() => {
  return formatToLongDateString(
    getCustomerWantedStartDate(updatedTender.value),
  );
});

const requestSent = computed(() => {
  return formatToLongDateString(getPriceRequestSentDate(updatedTender.value));
});

const jointInspection = computed(() => {
  return formatToLongDateString(getSurveyDateTime(updatedTender.value));
});

const sharedMessage = computed(() => {
  const sharedMessage = getPriceRequestMessage(updatedTender.value);
  return sharedMessage ? sharedMessage : "Ingen delte meldinger for øyeblikket";
});
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type TabComponent = DefineComponent<any, any, any>;

const tabComponents = new Map<string, TabComponent>([
  [TenderViewTabLabels.priceRequest, PriceRequestDetails as TabComponent],
  [TenderViewTabLabels.messages, MessagesWrapper as TabComponent],
  [TenderViewTabLabels.agreement, AgreementWrapper as TabComponent],
  [TenderViewTabLabels.offers, SupplierOffersWrapper as TabComponent],
  [TenderViewTabLabels.documentation, ServiceReportWrapper as TabComponent],
]);

const getComponentProps = (tabLabel: string) => {
  const baseProps = {
    tender: updatedTender.value,
    supplierId: props.supplierId,
  };

  switch (tabLabel) {
    case TenderViewTabLabels.priceRequest:
      return {
        ...baseProps,
        referenceCode: referenceCode.value,
        serviceCategory: serviceCategory.value,
        requestSent: requestSent.value,
        desiredStartDate: desiredStartDate.value,
        jointInspection: jointInspection.value,
        serviceOfferId: selectedServiceOfferId.value,
        sharedMessage: sharedMessage.value,
      };
    case TenderViewTabLabels.messages:
      return baseProps;
    case TenderViewTabLabels.agreement:
      return baseProps;
    case TenderViewTabLabels.offers:
      return { ...baseProps, frequencyOptions: frequencyOptions.value };
    case TenderViewTabLabels.documentation:
      return baseProps;
    default:
      return baseProps;
  }
};

const currentTabs = computed(() => {
  const tabs = {
    priceRequest: {
      label: TenderViewTabLabels.priceRequest,
      title: "Prisforespørsel",
    },
    messages: { label: TenderViewTabLabels.messages, title: "Meldinger" },
    offers: { label: TenderViewTabLabels.offers, title: "Gi tilbud" },
    agreement: { label: TenderViewTabLabels.agreement, title: "Avtale" },
    documentation: {
      label: TenderViewTabLabels.documentation,
      title: "Dokumentasjon på utført arbeid",
    },
  };
  return isActiveAgreement.value
    ? [tabs.messages, tabs.agreement, tabs.documentation, tabs.priceRequest]
    : [tabs.priceRequest, tabs.messages, tabs.offers];
});

const activeTabLabel = computed(() => {
  const routeLabel = route.query.tab as string;
  const tabLabelValues = currentTabs.value.map((tab) => tab.label);
  return tabLabelValues.includes(routeLabel)
    ? routeLabel
    : tabLabelValues[0] ?? TenderViewTabLabels.priceRequest;
});

const supplierRequestId = computed(() => {
  if (!hasTenderWithPriceRequests()) return null;
  return updatedTender.value.priceRequests[0]?.id;
});

const selectedServiceOfferId = computed(() => {
  if (!isTenderAvailable.value) return 0;
  const serviceTypePackagePortfolio = findSelectedServiceTypePackagePortfolio();
  for (const serviceOffer of serviceTypePackagePortfolio?.serviceOffers || []) {
    const aggregatedPrice = serviceOffer.serviceOfferPrices.find(
      (price) => price.priceType === "Aggregated",
    );
    if (aggregatedPrice) return aggregatedPrice.serviceOfferId;
  }
  return 0;
});

const isActiveAgreement = computed(() => {
  return !!findSelectedServiceTypePackagePortfolio();
});

const isCreatePriceRequestButtonVisible = computed(() => {
  return isTenderAvailable.value && isActiveAgreement.value;
});

const isGiveOfferButtonVisible = computed(() => {
  if (!isTenderAvailable.value) return false;
  return !(isTenderCompleted.value || isActiveAgreement.value);
});

const isRejectPriceButtonVisible = computed(() => {
  const isRequestStateRejected = isRequestRejected();
  const isTenderStateValid = VALID_STATES.includes(
    updatedTender.value?.tenderState as TenderState,
  );
  return !(isRequestStateRejected || isTenderStateValid);
});

const isMarkAsDoneButtonVisible = computed(() => {
  if (!isTenderAvailable.value || isTenderCompleted.value) return false;
  return isTenderSingleJob(updatedTender.value) && isActiveAgreement.value;
});

const isTenderAvailable = computed(
  () => !isTenderLoading.value && isUpdatedTenderPopulated(),
);

const isTenderCompleted = computed(
  () => updatedTender.value?.tenderState === TenderState.COMPLETED,
);

const userId = computed(() => userStore.user?.userId);

const getServiceTypeAndServiceCategory = () => {
  const { priceRequests } = updatedTender.value;
  const serviceCategoryLabel = priceRequests[0]?.serviceCategoryLabel;
  if (!serviceCategoryLabel) return {};
  const serviceTypesCms = cmsStore[
    ContentType.SERVICE_TYPES_CMS
  ] as CmsServiceType[];
  const matchedServiceType = findMatchingCmsServiceType(
    serviceTypesCms,
    serviceCategoryLabel,
  );
  if (!matchedServiceType) return {};
  const matchedServiceCategory = findMatchingServiceCategory(
    matchedServiceType,
    serviceCategoryLabel,
  );

  return { matchedServiceType, matchedServiceCategory };
};

const isUpdatedTenderPopulated = (): boolean => {
  return Object.keys(updatedTender.value).length > 0;
};

const isRequestRejected = (): boolean => {
  const requestState =
    updatedTender.value?.priceRequests?.[0]?.supplierRequests?.[0]
      ?.requestState;
  return requestState === RequestState.REJECTED;
};

const hasTenderWithPriceRequests = (): boolean => {
  return !!updatedTender.value?.priceRequests?.[0];
};

const fetchTenderDetails = async (): Promise<void> => {
  try {
    const response = await getTender({
      supplierId: props.supplierId,
      tenderId: props.tender.tenderId,
    });
    updatedTender.value = response.data;
  } catch (error) {
    console.error("Error fetching tender details:", error);
  } finally {
    isTenderLoading.value = false;
  }
};

const fetchActivityFormFrequencyOptions = async (): Promise<void> => {
  try {
    const response = await getActivityFormFrequencyOptions();
    frequencyOptions.value = response.data;
  } catch (error) {
    console.error("Error fetching activity form frequency options:", error);
    frequencyOptions.value = null;
  }
};

const fetchServiceCategories = async () => {
  try {
    const response = await getSupplierServiceCategories({
      supplierId: props.supplierId,
    });
    const categories = response.data;
    const cms = cmsStore[ContentType.SERVICE_TYPES_CMS] as CmsServiceType[];
    const serviceTypes = supplierStore.serviceTypes;
    supplierServiceTypes.value = formatServiceTypeCategories(
      categories,
      cms,
      serviceTypes,
    );
  } catch (error) {
    console.error("Error fetching service categories:", error);
  }
};

const handleClickedTab = (tab: { label: string }): void => {
  if (tab?.label) {
    router.replace({
      name: route.name as string,
      query: { ...route.query, tab: tab.label },
    });
  } else {
    console.error("Invalid tab parameter:", tab);
  }
};

const handleNewOfferButtonClick = (): void => {
  if (updatedTender.value?.tenderId) {
    router.push({
      name: RouteNames.CREATE_OFFER,
      params: { tenderId: updatedTender.value.tenderId.toString() },
    });
  } else {
    console.error("Invalid tenderId:", updatedTender.value?.tenderId);
  }
};

const onOfferCompleted = (): void => {
  showCompleteTenderOfferModal.value = false;
  refreshPageUrl();
};

const handleOnPriceRejectedSuccess = (): void => {
  refreshPageUrl();
};

const refreshPageUrl = (): void => {
  const serviceOffer = getServiceOfferFromTender(
    selectedServiceOfferId.value,
    updatedTender.value,
  );
  const currentUrl = window.location.href;
  const newUrl = serviceOffer
    ? currentUrl
    : currentUrl.replace("/my-requests", "/my-agreements");
  window.location.replace(newUrl);
};

const onPriceRejected = (): void => {
  showRejectPriceRequestModal.value = false;
  showRejectPriceRequestSuccessModal.value = true;
};

const handleCreatePriceRequestClick = (): void => {
  showCreatePriceRequestModal.value = true;
};

const handleRejectPriceRequestClick = (): void => {
  showRejectPriceRequestModal.value = true;
};

const handleMarkAsDoneClick = (): void => {
  showCompleteTenderOfferModal.value = true;
};

const findSelectedServiceTypePackagePortfolio =
  (): ServiceTypePackagePortfolio | null => {
    const { supplierPortfolios } = updatedTender.value || {};
    if (!supplierPortfolios?.length) return null;

    for (const supplierPortfolio of supplierPortfolios) {
      const { serviceTypePackagePortfolio } = supplierPortfolio;
      if (Array.isArray(serviceTypePackagePortfolio)) {
        const selectedPortfolio = serviceTypePackagePortfolio.find(
          (portfolio) => isServiceTypePackagePortfolioSelected(portfolio),
        );
        if (selectedPortfolio) return selectedPortfolio;
      }
    }
    return null;
  };

const isServiceTypePackagePortfolioSelected = (
  serviceTypePackagePortfolio: ServiceTypePackagePortfolio,
): boolean => {
  const { offerStatus } = serviceTypePackagePortfolio || {};
  return (
    offerStatus === OfferStatus.ACCEPTED ||
    offerStatus === OfferStatus.COMPLETED
  );
};

// Utility functions
const findMatchingCmsServiceType = (
  cms: CmsServiceType[],
  categoryLabel: string,
) =>
  cms.find((serviceType) =>
    serviceType.serviceCategories?.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (cat: any) => cat.label === categoryLabel,
    ),
  );

const findMatchingServiceType = (
  serviceTypes: ServiceType[],
  cmsServiceType: CmsServiceType,
) =>
  serviceTypes.find(
    (serviceType) => serviceType.sysLabel === cmsServiceType.label,
  );

const findMatchingServiceCategory = (
  cmsServiceType: CmsServiceType,
  categoryLabel: string,
) =>
  cmsServiceType.serviceCategories?.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cat: any) => cat.label === categoryLabel,
  );

const logWarning = (message: string) => console.warn(message);

// Main function
const formatServiceTypeCategories = (
  categories: ServiceCategory[],
  cms: CmsServiceType[],
  serviceTypes: ServiceType[],
): FormattedServiceType[] => {
  if (!cms || !serviceTypes) {
    logWarning("CMS or serviceTypes is missing");
    return [];
  }

  const formattedServiceTypes = categories.reduce(
    (acc, category) => {
      const { categoryLabel, id } = category;
      const matchedCmsServiceType = findMatchingCmsServiceType(
        cms,
        categoryLabel,
      );

      if (!matchedCmsServiceType) {
        logWarning(
          `No matching CMS service type found for category ${categoryLabel}`,
        );
        return acc;
      }

      const supplierTypeLabel = matchedCmsServiceType.label;
      const matchedServiceType = findMatchingServiceType(
        serviceTypes,
        matchedCmsServiceType,
      );

      if (!matchedServiceType) {
        logWarning(
          `No matching supplier type found for label ${matchedCmsServiceType.label}`,
        );
        return acc;
      }

      if (!acc[supplierTypeLabel]) {
        acc[supplierTypeLabel] = createFormattedServiceType(
          matchedServiceType,
          matchedCmsServiceType,
        );
      }

      const formattedCategory = createFormattedCategory(
        matchedCmsServiceType,
        categoryLabel,
        id,
      );
      acc[supplierTypeLabel].categories.push(formattedCategory);

      return acc;
    },
    {} as Record<string, FormattedServiceType>,
  );

  return Object.values(formattedServiceTypes);
};

const createFormattedServiceType = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  matchedServiceType: any,
  matchedCmsServiceType: CmsServiceType,
): FormattedServiceType => ({
  id: matchedServiceType.id,
  title: matchedCmsServiceType.name || matchedCmsServiceType.label,
  label: matchedCmsServiceType.label,
  categories: [],
});

const createFormattedCategory = (
  matchedCmsServiceType: CmsServiceType,
  categoryLabel: string,
  id?: number,
): FormattedCategory => {
  const matchedServiceCategory = findMatchingServiceCategory(
    matchedCmsServiceType,
    categoryLabel,
  );

  if (!matchedServiceCategory) {
    logWarning(
      `No matching service category found for ${categoryLabel} in ${matchedCmsServiceType.label}`,
    );
  }

  return {
    title: matchedServiceCategory?.title || categoryLabel,
    label: categoryLabel,
    id,
  };
};

watch(
  () => supplierStore.supplier,
  () => {
    if (isActiveAgreement.value) {
      fetchServiceCategories();
    }
  },
  { immediate: true, deep: true },
);

onMounted(async () => {
  try {
    await fetchTenderDetails();
    await fetchActivityFormFrequencyOptions();

    if (isActiveAgreement.value) {
      await fetchServiceCategories();
    }
  } catch (error) {
    console.error("Error in onMounted hook:", error);
  }
});
</script>

<style lang="scss" scoped>
.tender-list-detail-content {
  min-height: 30rem;

  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: $color-primary;
    margin: auto;
  }
}

.tender-detail-content {
  background-color: #fff;
  padding: 2rem 4rem;
  cursor: default;
}
</style>
